<template>
    <div class="content_container notice_view">
        <div class="content">
            <div class="banner">
                <h1>약관 및 규정</h1>
            </div>
            
            <ListBoard whichBoard="termsCredit" />
        </div>
    </div>
</template>

<script>
import ListBoard from '@/components/ListBoard'

export default {
	name : 'TermsCredit',
	components : {
		ListBoard,
	},
}
</script>

